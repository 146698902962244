import Amplify from "aws-amplify";
import "core-js/stable";
import { StrictMode } from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./2.0/translations/initDayjs";
import "./2.0/translations/initI18n";
import { getBuildText } from "./2.0/utils/getBuildText";
import App from "./App";
import "./index.css";

const authDev2 = {
  Auth: {
    region: "ca-central-1",
    userPoolId: "ca-central-1_rWdShkWaw",
    userPoolWebClientId: "13cfdvmggd3u71k77g1rf1n6lv",
  },
  storage: sessionStorage,
};

const authStaging2 = {
  Auth: {
    region: "ca-central-1",
    userPoolId: "ca-central-1_c5x8ldJYO",
    userPoolWebClientId: "6pnljetumc372b9ka5ohrh288e",
  },
  storage: sessionStorage,
};

const authTraining2 = {
  Auth: {
    region: "ca-central-1",
    userPoolId: "ca-central-1_cAUG2JYDk",
    userPoolWebClientId: "6blu1claej6hie2d7kr3rvjt50",
  },
  storage: sessionStorage,
};

const authProd2 = {
  Auth: {
    region: "ca-central-1",
    userPoolId: "ca-central-1_tKxIIq4j6",
    userPoolWebClientId: "58os7u6k0ij14utabnaokhphm1",
  },
  storage: sessionStorage,
};

const { REACT_APP_ENVIRONMENT } = process.env;
let environment = "";
if (
  !REACT_APP_ENVIRONMENT ||
  REACT_APP_ENVIRONMENT === "dev" ||
  REACT_APP_ENVIRONMENT === "staging" ||
  REACT_APP_ENVIRONMENT === "trn"
) {
  environment = REACT_APP_ENVIRONMENT;
  console.log("Running environment --", REACT_APP_ENVIRONMENT);
}

const authEnv = () => {
  switch (REACT_APP_ENVIRONMENT) {
    case "dev2":
      return authDev2;
    case "qa2":
    case "staging2":
      return authStaging2;
    case "trn2":
      return authTraining2;
    case "main2":
    case "prod2":
      return authProd2;
    default:
      throw new Error(
        `failed to find auth config for env ${REACT_APP_ENVIRONMENT}`
      );
  }
};

console.log(`build info: `, getBuildText());

Amplify.configure(authEnv());

ReactDOM.render(
  <StrictMode>
    <App environment={environment} />
  </StrictMode>,

  document.getElementById("root")
);
